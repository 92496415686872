import React, { useContext, useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { ActualFileObject, FilePondInitialFile } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFilePoster from "filepond-plugin-file-poster";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css";
import axios from "axios";

import { allowedFiles, propsFilepond } from "../assets/constants/filepond";
import endpoints from "../helpers/endpoints";
import { FileType } from "../types/Resource";
import { authHeader } from "../helpers/auth";
import { NewRequestContext } from "../contexts/NewRequestContext";
import { useParams } from "react-router";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize, FilePondPluginImagePreview, FilePondPluginFilePoster);

interface FileSubmitType {
  setFileLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fileName: string | null;
  type: FileType;
  maxFiles?: number;
}

export const FileSubmit = ({ setFileLoading, type, fileName, maxFiles = 1 }: FileSubmitType) => {
  const { idApplication } = useParams();
  const { application, person, setPerson } = useContext(NewRequestContext);
  const id = type === FileType.DNI_BACK || type === FileType.DNI_FRONT ? person.id : application.id || idApplication;
  const route = type === FileType.DNI_BACK || type === FileType.DNI_FRONT ? "dni" : type;
  const [file, setFile] = useState<undefined | ActualFileObject>();
  const [fileAutoloaded, setFileAutoloaded] = useState<FilePondInitialFile[] | undefined>();
  const { headers } = authHeader();

  useEffect(() => {
    if (fileName) {
      const loadFile = async () => {
        try {
          const response = await axios.get(`${endpoints.resourceBase}/descargar-archivo/${route}/${fileName}`, { headers });
          setFileAutoloaded([
            {
              source: fileName,
              options: {
                type: "local",
                metadata: {
                  poster: response.data,
                },
              },
            },
          ]);
        } catch (error) {
          console.error("Error loading file:", error);
        }
      };
      loadFile();
    }
  }, [fileName]);

  const handleRemoveFile = async (fileToRemove: string) => {
    try {
      await axios.delete(`${endpoints.resourceBase}/${endpoints.deleteFile}/${id}/${route}/${fileToRemove}`, { headers });
      if (type === FileType.DNI_FRONT) {
        setPerson({ ...person, dniFileFront: null });
      } else if (type === FileType.DNI_BACK) {
        setPerson({ ...person, dniFileBack: null });
      }
      setFileAutoloaded([]);
      return true;
    } catch (error) {
      console.error("Error removing file:", error);
      return false;
    }
  };

  return (
    <FilePond
      credits={false}
      allowMultiple={maxFiles > 1}
      maxFiles={maxFiles}
      files={file ? [file] : fileAutoloaded}
      server={{
        url: `${endpoints.resourceBase}`,
        process: {
          url: `/${endpoints.uploadFile}/${type}/${id}`,
          headers,
        },
        revert: async (source, load) => {
          try {
            await handleRemoveFile(source);
            setFile(undefined);
            load();
          } catch (error) {
            console.error("Error removing file:", error);
          }
        },
        restore: null,
        load: async (source, load) => {
          try {
            const response = await axios.get(`${endpoints.resourceBase}/descargar-archivo/${route}/${source}`, { headers });
            load(response.data);
          } catch (error) {
            console.error("Error loading file:", error);
          }
        },
        fetch: null,
        remove: async (source, load) => {
          try {
            await handleRemoveFile(source);
            setFile(undefined);
            load();
          } catch (error) {
            console.error("Error removing file:", error);
          }
        },
      }}
      onprocessfilestart={() => setFileLoading(true)}
      onprocessfile={(error, file) => {
        setFileAutoloaded([]);
        setFileLoading(false);
        setFile(file.file);
        if (type === FileType.DNI_FRONT) {
          setPerson({ ...person, dniFileFront: file.serverId });
        } else if (type === FileType.DNI_BACK) {
          setPerson({ ...person, dniFileBack: file.serverId });
        }
      }}
      onremovefile={() => {
        setFileAutoloaded([]);
        setFileLoading(false);
      }}
      onaddfilestart={() => setFileLoading(true)}
      onaddfile={(error, file) => {
        setFile(file.file);
        setFileAutoloaded([]);
        setFileLoading(false);
      }}
      onerror={() => setFileLoading(false)}
      allowFileTypeValidation={true}
      acceptedFileTypes={allowedFiles}
      allowFileSizeValidation={true}
      maxFileSize="20MB"
      name="files"
      {...propsFilepond}
    />
  );
};
